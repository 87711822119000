import CardMessage from '../../../../../components/CardMessage'
import iconConectPending from '../../../../../assets/images/conect_pending_icon.svg'
import iconConectSuccess from '../../../../../assets/images/conect_success_icon.svg'

export default {
  name: 'ConectedSuccess',
  props: {
    code: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      iconConectPending,
      iconConectSuccess,
    }
  },

  components: {
    CardMessage,
  },

  computed: {
    isPending() {
      return this.status === 'PENDING_MATCH'
    },
  },
}
